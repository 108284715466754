const vtGrey = {
  100: '#EDEDEC',
  300: '#A5A5A2',
  500: '#4B4B46',
  700: '#141412',
  hover: '#787875',
  active: '#4B4B46',
  disabled: '#EDEDEC',
  //A200:
  //A400:
  //A700:
};

export default vtGrey;
