const vtRed = {
  100: '#FBE5ED',
  300: '#EB7FA5',
  500: '#D7004B',
  700: '#8F0032',
  active: '#8F0032',
  hover: '#EB7FA5',
  disabled: '#FBE5ED',
  //700:
  //A200:
  //A400:
  //A700:
};

export default vtRed;
